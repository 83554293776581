@import 'src/utils/utils';

.article-intro {
  margin-top: 15px;
  margin-right: 16px;
  margin-left: 16px;
  margin-bottom: 15px;

  @media(min-width: $lg) {
    margin-top: 30px;
    margin-right: 0;
    margin-bottom: 30px;
    margin-left: 0;
  }
  @media(min-width: $xxl) {
    @include box;
  }

  &__link {
    @include zero;
    @include Text-14-bold;

    svg {
      display: none;
    }

    a {
      @include Text-14-reg;
      position: relative;
      display: inline-block;
      padding-left: 22px;
      text-decoration: none;
      color: $Corporate-2;

      @media(min-width: $lg) {
        padding-left: 28px;
      }
      @media(min-width: $xl) {
        @include Text-16-reg;
      } 

      &:hover {
        @media(min-width: $lg) {
          text-decoration: underline;
        }
      }

      &:before {
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        background-image: url('../../assets/images/link.svg');
        background-size: contain;
        background-position: center;
        content: '';

        @media(min-width: $lg) {
          top: 2px;
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &__header {
    @include zero;
    @include Head-35-bold;
    color: $Main-1;
    margin-top: 10px;
    margin-bottom: 10px;

    @media(min-width: $xxl) {
      @include Head-60-bold;
      margin-top: 15px;
      margin-bottom: 20px;
    }
  }

  &__legal {
    @include zero;
    @include Text-14-reg;
    color: $t-60;
  }
}